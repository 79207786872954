import { adjust_geofence_summary_kmService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, adjust_geofence_summary_kms: [] };

const actions = {
    adjust_geofence_summary_km({ dispatch, commit }, data) {
        commit('request');

        adjust_geofence_summary_kmService.adjust_geofence_summary_km(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/adjust_geofence_summary_km');
                    dispatch('alert/success', "Geofence Summary Adjusted Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/adjust_geofence_summary_km');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_geofence_summary_km({ dispatch, commit }, data) {
        commit('request');

        adjust_geofence_summary_kmService.get_geofence_summary_km(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  adjust_geofence_summary_kms: state => {
    return state.adjust_geofence_summary_kms
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.adjust_geofence_summary_kms = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.adjust_geofence_summary_kms = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const adjust_geofence_summary_kms = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
