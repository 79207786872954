import { custom_event_alertService } from '../_services';
import { router } from '../_helpers';
const state = { onRequest: false, custom_event_alerts: [], custom_event_alert: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        custom_event_alertService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        custom_event_alertService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/monitor_custom_event_alerts');
                    dispatch('alert/success', "Custom Event Alert Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/monitor_custom_event_alerts');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_data({ dispatch, commit }, data) {
        commit('request');

        custom_event_alertService.get_data(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    clear_data({ commit }) {
        commit('clear_data');
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  custom_event_alerts: state => {
    return state.custom_event_alerts
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.custom_event_alerts = [];
        state.custom_event_alert = {};
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.custom_event_alert = data;
    },
    success_datas(state, data) {
        state.onRequest = false;
      state.custom_event_alerts = data;
    },
    clear_data(state) {
      state.onRequest = false;
      state.custom_event_alerts = [];
      state.custom_event_alert = {};
    },
    error(state) {
        state.onRequest = false;
    },
};

export const custom_event_alerts = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
