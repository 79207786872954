import { checklist_itemService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, checklist_items: [], checklist_item: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        checklist_itemService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        checklist_itemService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/checklist_items');
                    dispatch('alert/success', "Checklist Item Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/checklist_items');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        checklist_itemService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/checklist_items');
                    dispatch('alert/success', "Checklist Item Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/checklist_items');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        checklist_itemService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      checklist_itemService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/checklist_items');
                  dispatch('alert/success', "Checklist Item Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/checklist_items');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_checklist_item_by_model({ dispatch, commit }, data) {
        commit('request');

        checklist_itemService.get_checklist_item_by_model(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_checklist_item_by_account({ dispatch, commit }, data) {
        commit('request');

        checklist_itemService.get_checklist_item_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  checklist_items: state => {
    return state.checklist_items
  },
  checklist_item: state => {
    return state.checklist_item
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.checklist_item = {};
        state.checklist_items = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.checklist_item = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.checklist_items = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const checklist_items = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
