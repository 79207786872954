import { checklist_modelService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, checklist_models: [], checklist_model: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        checklist_modelService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        checklist_modelService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/checklist_models');
                    dispatch('alert/success', "Checklist Model Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/checklist_models');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        checklist_modelService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/checklist_models');
                    dispatch('alert/success', "Checklist Model Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/checklist_models');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        checklist_modelService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      checklist_modelService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/checklist_models');
                  dispatch('alert/success', "Checklist Model Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/checklist_models');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_checklist_model_by_account({ dispatch, commit }, data) {
        commit('request');

        checklist_modelService.get_checklist_model_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  checklist_models: state => {
    return state.checklist_models
  },
  checklist_model: state => {
    return state.checklist_model
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.checklist_model = {};
        state.checklist_models = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.checklist_model = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.checklist_models = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const checklist_models = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
