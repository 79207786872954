import { ceiba_deviceService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, ceiba_devices: [], ceiba_device: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        ceiba_deviceService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        ceiba_deviceService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/ceiba_devices');
                    dispatch('alert/success', "Ceiba Device Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/ceiba_devices');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        ceiba_deviceService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/ceiba_devices');
                    dispatch('alert/success', "Ceiba Device Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/ceiba_devices');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        ceiba_deviceService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      ceiba_deviceService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/ceiba_devices');
                  dispatch('alert/success', "Ceiba Device Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/ceiba_devices');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_ceiba_device_by_account({ dispatch, commit }, data) {
        commit('request');

        ceiba_deviceService.get_ceiba_device_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  ceiba_devices: state => {
    return state.ceiba_devices
  },
  ceiba_device: state => {
    return state.ceiba_device
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.ceiba_device = {};
        state.ceiba_devices = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.ceiba_device = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.ceiba_devices = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const ceiba_devices = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
