import { deliveryService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, deliveries: [], delivery: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        deliveryService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        deliveryService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/deliveries');
                    dispatch('alert/success', "Delivery Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/deliveries');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        deliveryService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/deliveries');
                    dispatch('alert/success', "Delivery Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/deliveries');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        deliveryService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      deliveryService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/deliveries');
                  dispatch('alert/success', "Delivery Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/deliveries');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_delivery_by_account({ dispatch, commit }, data) {
        commit('request');

        deliveryService.get_delivery_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  deliveries: state => {
    return state.deliveries
  },
  delivery: state => {
    return state.delivery
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.delivery = {};
        state.deliveries = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.delivery = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.deliveries = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const deliveries = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
