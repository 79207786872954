import { driver_loginService } from '../_services';
const state = { onRequest: false, driver_logins: [] };

const actions = {
    get_data({ dispatch, commit }, data) {
        commit('request');

        driver_loginService.get_data(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    clear_data({ commit }) {
        commit('clear_data');
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  driver_logins: state => {
    return state.driver_logins
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.driver_logins = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_datas(state, data) {
        state.onRequest = false;
      state.driver_logins = data;
    },
    clear_data(state) {
      state.onRequest = false;
      state.driver_logins = [];
    },
    error(state) {
        state.onRequest = false;
    },
};

export const driver_logins = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
