import { gps_cameraService } from '../_services';
import { router } from '../_helpers';

const state = { onRequest: false, gps_cameras: [], gps_camera: {} };

const actions = {
    get({ dispatch, commit }, id) {
        commit('request');

        gps_cameraService.get(id)
            .then(
                resp => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    add({ dispatch, commit }, data) {
        commit('request');

        gps_cameraService.add(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/gps_cameras');
                    dispatch('alert/success', "GPS Camera Added Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/gps_cameras');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    edit({ dispatch, commit }, data) {
        commit('request');

        gps_cameraService.edit(data)
            .then(
                async () => {
                    commit('success');
                    await router.push('/gps_cameras');
                    dispatch('alert/success', "GPS Camera Updated Successfully", { root: true });
                },
                async error => {
                    commit('error');
                    await router.push('/gps_cameras');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }) {
        commit('request');

        gps_cameraService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    destroy({ dispatch, commit }, id){
      commit('request');

      gps_cameraService.destroy(id)
          .then(
              async () => {
                  commit('success');
                  await router.go('/gps_cameras');
                  dispatch('alert/success', "GPS Camera Deleted Successfully", { root: true });
              },
              async error => {
                  commit('error');
                  await router.go('/gps_cameras');
                  dispatch('alert/error', error, { root: true });
              }
          );
    },
    get_gps_camera_by_account({ dispatch, commit }, data) {
        commit('request');
        gps_cameraService.get_gps_camera_by_account(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  gps_cameras: state => {
    return state.gps_cameras
  },
  gps_camera: state => {
    return state.gps_camera
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.gps_camera = {};
        state.gps_cameras = [];
    },
    success(state) {
      state.onRequest = false;
    },
    success_data(state, data) {
      state.onRequest = false;
      state.gps_camera = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.gps_cameras = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const gps_cameras = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
